'use strict';

const integrationsCart = require('integrations/cart');
const cart = require('./cart/cart');

integrationsCart.baseFiles.cart = function () {
    return cart.init.call(cart);
};

module.exports = integrationsCart;
