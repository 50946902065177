'use strict';

var scrollAnimate = require('../components/scrollAnimate');
var recaptcha = require('core/components/recaptcha');
var rolexTracking = require('../components/rolexTracking');

module.exports = {
    showPDPContactUsForm: function() {
        $('.js-reset-contactus-form').on('click', function () {
            $('.js-contactus-success').addClass('d-none');
            $('.js-pdp-contactus-wrapper').removeClass('d-none');
            $('.js-contactus-success-text').empty();
        });
    },
    subscribeProductContact: function () {
        $('form.js-pdp-contact-us').submit(function (e) {
            e.preventDefault();
            var form = $(this);
            var button = $('.subscribe-contact-us');
            var url = form.attr('action');
            var pid = $('.product-detail').data('pid');
            var isRolex = form.closest('.js-rolex-contact-us').length;
            var emailDataError = $('#contact-email').siblings('.invalid-feedback');
            var phoneDataError = $('#contact-phone').siblings('.invalid-feedback');

            if (form.find('#contact-email').val() || form.find('#contact-phone').val()) {
                form.find('.js-contactus-pid').val(pid);
                $('.js-contact-us-form-error').empty().hide();
                emailDataError.empty().hide();
                phoneDataError.empty().hide();

                $.spinner().start();
                button.attr('disabled', true);

                recaptcha.check(e, {
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (data) {
                        if (data.success) {
                            $('.js-pdp-contact-us').trigger('reset');
                            $('.js-contactus-success-text').text(data.msg);
                            $('.js-pdp-contactus-wrapper').addClass('d-none');
                            $('.js-contactus-success').removeClass('d-none');

                            scrollAnimate($('.js-contactus-success'), 50);

                            // Rolex Tracking
                            if (isRolex) {
                                rolexTracking.methods.triggerTracking('contactForm');
                            }
                        }

                        button.removeAttr('disabled');
                        $.spinner().stop();
                    },
                    error: function (err) {
                        button.removeAttr('disabled');
                        $.spinner().stop();
                    }
                });
            } else {
                var emailDataErrorMessage = emailDataError.data('both-inputs-empty');
                var phoneDataErrorMessage = phoneDataError.data('both-inputs-empty');
                
                emailDataError.text(emailDataErrorMessage).show();
                phoneDataError.text(phoneDataErrorMessage).show();
            }
        });
    }
};