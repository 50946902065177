'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');

var isMobile = window.isMobile();
var sitePreferencesUtils = require('core/preferences/sitePreferencesUtils');
var preferences = sitePreferencesUtils.getSitePreferences();
var SiteConstants = require('constants/SiteConstants');
// Original vars from search.js
var minChars = preferences.headerSearchMinTermLength;
var UP_KEY = SiteConstants.UP_KEY;
var DOWN_KEY = SiteConstants.DOWN_KEY;
var DIRECTION_DOWN = SiteConstants.DIRECTION_DOWN;
var DIRECTION_UP = SiteConstants.DIRECTION_UP;



/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper() {
    return $('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('.modal-background').hide();
    $('header').siblings().attr('aria-hidden', 'false');
    clearAnimationAndTranstion();
}

/**
 * Remove animation (none) and transform (none) class that was applied in applyModals so that
 * scroll-direction-up and scroll-direction-down can apply their own animation styling
 */
function clearAnimationAndTranstion() {
    $('.header-nav').css('animation', '');
    $('.header-nav').css('transform', '');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');

        if (!isMobileSearch(scope)) {
            $('.modal-background').show();
        }

        // Set animation and transform to none as that is interfering with displaying the search results
        $('.header-nav').css('animation', 'none');
        $('.header-nav').css('transform', 'none');
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('');
    clearModals();
    if (isMobile) {
        $('input.search-field').css('background-color', '#F8F8F8');
    }
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
 function positionSuggestions(scope) {
    var outerHeight;

    if (isMobileSearch(scope)) {
        outerHeight = $('.header-row').outerHeight() + $('.header-banner').outerHeight() + $('.search-mobile-row').outerHeight();
        $('.suggestions-wrapper').css('top', outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $('.suggestions-wrapper').scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    } else {
        const $suggestionsWrapper = $('.suggestions-wrapper');
        const $mainMenu = $('.header-nav .main-menu');
        if ($mainMenu.length) {
            const bounds = $mainMenu[0].getBoundingClientRect();
            const menuBottomPosition = bounds.bottom;
            $suggestionsWrapper.css('top', menuBottomPosition);
        }
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (typeof (response) !== 'object') {
        $suggestionsWrapper.append(response).show();
        $(this).siblings('.reset-button').removeClass('d-none').addClass('d-sm-block');
        if (isMobile) {
            $('input.search-field').css('background-color', 'white');
        }
        positionSuggestions(this);
        applyModals(this);

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
    } else {
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        $(scope).siblings('.reset-button').addClass('d-none').removeClass('d-sm-block');
        clearModals();
        $('.modal-background').hide();
        getSuggestionsWrapper(scope).empty();
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

module.exports = function () {
    $('form[name="simpleSearch"]').submit(function (e) {
        var suggestionsList = $('.suggestions .item');
        if (suggestionsList.filter('.selected').length !== 0) {
            e.preventDefault();
            suggestionsList.filter('.selected').find('a')[0].click();
        }
    });

    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    $('body').on('click', function (e) {
        if (!$('.suggestions').has(e.target).length && !$(e.target).hasClass('search-field')) {
            $('.suggestions').hide();
        }
    });

    $('body').on('click touchend', '.search-mobile button.fa-close', function (e) {
        e.preventDefault();
        $('.suggestions-wrapper').hide();
        $('.modal-background').hide();
        tearDownSuggestions();
        clearAnimationAndTranstion();
    });

    $('.site-search .reset-button').on('click', function () {
        $(this).addClass('d-none').removeClass('d-sm-block');
        tearDownSuggestions();
    });
};
