'use strict';

module.exports = function (element, additionalOffset) {
    var hasFixedHeader = $('header').hasClass('fixed-header');
    var hasFixedHeaderEnhanced = $('header').hasClass('fixed-header-enhanced');
    var position = 0;

    if (element && element.length) {
        var headerNavHeight = $('.header-nav').length ? $('.header-nav').height() : 0;
        var headerBannerHeight = $('.header-banner').length ? $('.header-banner').height() : 0;
        var utilityBannerHeight = $('.utility-header').length ? $('.utility-header').height() : 0;
        var fullHeaderHeight = (headerNavHeight + headerBannerHeight + utilityBannerHeight) * 2;

        if (hasFixedHeader || hasFixedHeaderEnhanced) {
            if (element.offset().top > fullHeaderHeight) {
                position = element.length ? element.offset().top - headerNavHeight : 0;
            }
        } else {
            position = element.offset().top;
        }

        /* Used for the mobile PDP pages to determine if the errored section is above or below the headers
        because the promo and mobile search headers will be hidden when scrolling down. When they're shown we have
        to use an offset so that the errored section is scrolled to correctly.
        If the errored section is above the header, the page will scroll up and all the mobile headers will show.
        If it's below the header, the page will scroll down and the promo and search headers will be hidden.
        */
        if (additionalOffset) {
            var positionOfErroredElement = element.offset().top - $(window).scrollTop();
            var isErrorAboveHeader = positionOfErroredElement < fullHeaderHeight;

            if (isErrorAboveHeader) {
                position -= (additionalOffset + headerBannerHeight);
            }
        }
    }

    $('html, body').animate({
        scrollTop: position
    }, 500);

    if (!element) {
        $('.logo-home').focus();
    }
};
