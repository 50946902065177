'use strict';
const tinySlider = require('tiny-slider/src/tiny-slider');

const _CONSTANTS = {
    selectors: {
        carousel: '[pd-carousel-product]'
    }
}

function initTinyCarousel(container) {
    this.widgetID = null;

    this.init = function () {
        this.initComponents();
        this.registrateContainer();
        this.initEvents();
        this.initCarousel();
    }

    this.initComponents = function () {
        this.components = {
            container: container
        }

        this.components.carousel = this.components.container.querySelector('[carousel]');
        this.components.controls = this.components.container.querySelector('[controls]');
        this.components.navigation = this.components.container.parentElement.querySelector('[navigation]');
        this.components.navigationMobile = this.components.container.parentElement.querySelector('[navigation-mobile]');
        this.components.leftArrow = this.components.container.querySelector('[left-arrow]');
        this.components.rightArrow = this.components.container.querySelector('[right-arrow]');
    }

    this.initCarousel = function () {
        const self = this;

        if (!this.components.carousel) {
            return null;
        }

        this.initialSettings = {
            container: self.components.carousel,
            controlsContainer: self.components.controls || false,
            items: 1,
            slideBy: 1,
            loop: false,
            mouseDrag: true,
            swipeAngle: false,
            speed: 400,
            mode: 'gallery',
            navContainer: window.innerWidth < 1023 ? this.components.navigationMobile : this.components.navigation,
            navAsThumbnails: true,
            nav: true,
            responsive: {
                1: {
                    controls: true
                },
                1024: {
                    controls: true
                }
            }
        }

        this.carousel = tinySlider.tns(this.initialSettings)

        self.handleArrows(this.carousel.getInfo());
        this.carousel.events.on('transitionStart', function (o) {
            self.handleArrows(o);
        });

        this.carousel.events.on('newBreakpointStart', function (o) {
            if (self.carousel) {
                self.carousel.destroy();
                self.initComponents();
                self.initCarousel();
            }
        });
    }

    this.handleArrows = function (o) {

        const startIndex = o.items;
        const currentIndex = o.index + o.items
        const lastIndex = o.slideCount;

        // left Arrow
        if (currentIndex === startIndex && this.components.leftArrow) {
            this.components.leftArrow.classList.add('hidden');
        } else {
            this.components.leftArrow.classList.remove('hidden');
        }

        // right Arrow
        if (currentIndex === lastIndex && this.components.rightArrow) {
            this.components.rightArrow.classList.add('hidden');
        } else {
            this.components.rightArrow.classList.remove('hidden');
        }
    }

    this.initEvents = function () {

    }

    this.terminate = function () {

    };

    this.registrateContainer = function () {
        if (!this.components.container) {
            return;
        }
        this.widgetID = this.genID();
        this.components.container.setAttribute('widget-id', this.widgetID)
    }

    this.genID = function () {
        return "10000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }
}

function tinyCarousel() {
    const containers = document.querySelectorAll(_CONSTANTS.selectors.carousel);

    if (containers && containers.length) {
        containers.forEach(container => {
            let widget = new initTinyCarousel(container);
            widget.init();
        });
    }
}

module.exports.init = function () {
    tinyCarousel();
};
