'use strict';
var base = require('base/components/clientSideValidation');

var submitOverride = function () {
        $('form').on('submit', function (e) {
            // Bypass SFRA form validation for formstack forms to avoid conflicts
            if ($(this).attr('id') && $(this).attr('id').startsWith('fsForm')) {
                return;
            }

            return base.validateForm.call($(this), e);
        });
};

base.submit = submitOverride;

module.exports = base;
