'use strict';

const integrations = require('integrations/product/base');
const scrollAnimate = require('../components/scrollAnimate');

function updateShippingMessage(data, $productContainer) {
    $productContainer.find('.js-shipping-message').text(data.product.shippingMessage);
    $productContainer.find('.js-shipping-cutoff').data('cutoff', data.product.shippingCutoff.cutoff);
    setCutoff();
};

$(document).on('onhandfastshippingMessage', function (event) {
    let container = $('.js-shipping-onHandFastShippingOnly');
    let message = container.data('onhandfastshipping');
    if (container.length < 1) {
        return;
    }
    container.html(message);
});

function setCutoff() {
    var container = $('.js-shipping-cutoff');

    if (container.length < 1) {
        return;
    }

    var cutoff = container.data('cutoff');

    if (!cutoff || cutoff === 'null') {
        container.html('');
        return;
    }

    try {
        var now = new Date();
        var deadline = new Date(cutoff);
        var remaining = deadline - now;

        if (remaining <= 0) {
            container.html('');
            return;
        }
        // 23minutes will round to 1 hour
        var hours = Math.ceil(remaining / (1000 * 60 * 60));
        var timeLeft = container.data('hours').replace('999', hours);

        if (hours > 24) {
            var days = Math.ceil(hours / 24);
            timeLeft = container.data('days').replace('999', days);
        } else if (hours === 1) {
            timeLeft = container.data('hour').replace('999', hours);
        }

        var message = container.data('message');
        message = message.replace('%%time%%', timeLeft);

        $(document).trigger('onhandfastshippingMessage');

        container.html(message);
    } catch (e) {
        container.html('');
    }
}

integrations.shippingCutoff = function () {
    setCutoff();
}
function engravingToolTipPopulator() {
    if ($('.tooltip-icon').hasClass('engraving-tooltip')) {
        var $tooltipEngravingMsgWrapper = $("<div>");
        $tooltipEngravingMsgWrapper.html($('.engraving-tooltip').data('original-title'));

        $tooltipEngravingMsgWrapper.find('span.engraving-cost').html($('#engravable_price').val());
        $tooltipEngravingMsgWrapper.find('span.engraving-character-limit').html($('#engrave_character_limit').val());

        $('.engraving-tooltip').attr('data-original-title', $tooltipEngravingMsgWrapper.html());
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
integrations.methods.handlePostCartAdd = function (response) {
    // conditional added for response, sometimes it was failing when called on page load
    if (response) {
        $('.minicart').trigger('count:update', response);
        var messageType = response.error ? 'alert-danger' : 'alert-success';
        var isMobile = window.isMobile();

        // show add to cart toast
        if (response.newBonusDiscountLineItem
            && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
            this.chooseBonusProducts(response.newBonusDiscountLineItem);
        } else {
            if (response.error) {
                if ($('.add-to-cart-messages').length === 0) {
                    $('body').append(
                        '<div class="add-to-cart-messages"></div>'
                    );
                }

                $('.add-to-cart-messages').append(
                    '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
                    + response.message
                    + '</div>'
                );
            } else {
                if (isMobile) {
                    $('.js-view-bag-button').css('display', 'block');
                    $('.add-to-cart').css('display', 'none');
                } else {
                    if ($('.search:visible').length === 0) {
                        return;
                    }

                    var url = $('.minicart').data('action-url');
                    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

                    if (count !== 0 && $('.minicart .popover.show').length === 0) {
                        $('.minicart .popover').addClass('show');
                        $('.minicart .popover').spinner().start();
                        $.get(url, function (data) {
                            $('.minicart .popover').empty();
                            $('.minicart .popover').append(data);

                            var isPaypalEnabled = !!($('#paypal_enabled').length > 0 && document.getElementById('paypal_enabled').value === 'true');
                            var isGooglePayEnabled = !!($('#isGooglePayEnabled').length > 0 && $('#isGooglePayEnabled').val() === 'true');

                            if (isPaypalEnabled) {
                                paypalhelper.paypalMini();
                            }
                            if (isGooglePayEnabled) {
                                onGooglePayLoaded();
                            }

                            $.spinner().stop();
                        });
                    }
                }

            }
        }

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
            $('.js-view-bag-button').css('display', 'none');
            $('.add-to-cart').css('display', 'block');
        }, 5000);
    }
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
integrations.methods.getOptions = function ($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            var isProductTile = $(this).closest('.product-detail').hasClass('product-tile');

            if (isProductTile) {
                selectedValueId = $(this).closest('.product-detail').find('.size-item.selected').data('value-id');
            }

            if (typeof selectedValueId == "undefined" && !isProductTile) {
                selectedValueId = $(this).find('input[name=selectedValueId]').val();
            }

            if (selectedValueId == 'engravable') {
                var optionsObj = {
                    optionId: $(this).data('option-id'),
                    selectedValueId: selectedValueId
                };

                optionsObj.engrave_price = $(this).find('input[name=engravable_price]').val() || "$0.00";
                optionsObj.engrave_message = $(this).find('input[name=engravable_message]').val() || "";

                return optionsObj;
            } else {
                return {
                    optionId: $(this).data('option-id'),
                    selectedValueId: selectedValueId
                }
            }
        }).toArray();

    return JSON.stringify(options);
};

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
integrations.methods.attributeSelect = function (selectedValueUrl, $productContainer) {
    var methods = this;

    if (selectedValueUrl && selectedValueUrl !== 'null') {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                methods.handleVariantResponse(data, $productContainer);
                updateShippingMessage(data, $productContainer);
                methods.updateOptions(data.product.optionsHtml, $productContainer);
                methods.updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $('body').trigger('tooltip:init');
                engravingToolTipPopulator();
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
};

integrations.readMore = () => {
    $(document).on('click', '.short-description .read-more', function (e) {
        e.preventDefault();
        $(this).addClass('d-none').removeClass('d-inline-block');
        $('.short-description .read-less').removeClass('d-none').addClass('d-inline-block');
        $('.short-description-text').css('display', 'inline-block');
    });

    $(document).on('click', '.short-description .read-less', function (e) {
        e.preventDefault();
        $(this).addClass('d-none').removeClass('d-inline-block');

        var $shortDescriptionText = $('.short-description-text');
        $shortDescriptionText.css('display', '-webkit-box');
        if ($shortDescriptionText.length && $shortDescriptionText.prop('scrollHeight') != $shortDescriptionText.prop('clientHeight')) {
            $('.short-description .read-more').removeClass('d-none').addClass('d-inline-block');
        }
    });

    $(document).ready(function () {
        hideShowReadButtons();
    });

    $(window).resize(function () {
        hideShowReadButtons();
    });
};

function hideShowReadButtons() {
    var $shortDescriptionText = $('.short-description-text');
    var $shortDescriptionReadMore = $('.short-description .read-more');
    var $shortDescriptionReadLess = $('.short-description .read-less');

    if ($shortDescriptionText.length) {
        if ($shortDescriptionText.prop('scrollHeight') == $shortDescriptionText.prop('clientHeight')) {
            $shortDescriptionReadMore.addClass('d-none').removeClass('d-inline-block');

            // If full description is displayed and the preview version has the same scroll and client height, remove the Read Less button
            // button and keep it in the clamped state so it can clamp properly if the window is resized smaller.
            if ($shortDescriptionText.css('display') == 'inline-block') {
                $shortDescriptionText.css('display', '-webkit-box');

                if ($shortDescriptionText.prop('scrollHeight') == $shortDescriptionText.prop('clientHeight')) {
                    $shortDescriptionReadLess.addClass('d-none').removeClass('d-inline-block');
                } else {
                    $shortDescriptionText.css('display', 'inline-block');
                }
            }
        } else {
            $shortDescriptionReadMore.addClass('d-inline-block').removeClass('d-none');
            $shortDescriptionReadLess.addClass('d-none').removeClass('d-inline-block');
        }
    };
};

integrations.engravingOption = function () {
    var scope = this;

    $(document).on('click', '.product-engraving-collapsible-content button', function (e) {
        e.preventDefault();

        if (scope.methods.checkForClickableAttribute($(this)) && !toggleObject.viewOutOfStockItems) {
            return;
        }
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail').length ? $(this).closest('.product-detail') : $(this).closest('.product-wrapper');
        }

        scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);
    });
};

integrations.engravingHelper = function () {
    $(document).ready(function () {
        engravingToolTipPopulator();
    });
}

/**********
     * shared across QV and PDP
     */
integrations.addToCart = function () {
    var scope = this;

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity;
        var isMobile = window.isMobile();
        var productDetailPage = $('.js-product-detail-page');

        $('body').trigger('product:beforeAddToCart', this);
        var productOptions = $(this).closest('.product-detail').find('.product-option');
        var optionError = false;
        if (isMobile && productDetailPage) {
            //Used for scrolling to the errored section on mobile
            var firstErroredOption = null;
        }

        productOptions.each(function () {
            var productOption = $(this);
            if (productOption.data('option-id') == 'engrave') {
                var engraveMessage = productOption.find('input[name=engravable_message]');
                var engraveMessageValueEmpty = engraveMessage.length > 0 && (engraveMessage.val() == null || engraveMessage.val() == '');

                if (engraveMessageValueEmpty) {
                    optionError = true;
                    $(this).closest('.product-detail').find('.invalid-feedback').css('display', 'block');
                    $(this).closest('.product-detail').find('.form-control').addClass('is-invalid');

                    if (isMobile && productDetailPage) {
                        if (firstErroredOption == null) {
                            firstErroredOption = productOption;
                        }
                    }
                };
            }

            if (productOption.data('option-id') == 'ringSizeProductOption') {
                var isProductTile = $(this).closest('.product-detail').hasClass('product-tile');

                let selectedOption;
                if (!isProductTile) {
                    selectedOption = productOption.find('option[selected]');
                } else {
                    selectedOption = $(this).closest('.product-detail').find('.size-item.selected');
                }

                if (selectedOption.length === 0 || selectedOption.data('value-id').length === 0) {
                    optionError = true;
                    $(this).closest('.product-detail').find('.invalid-feedback').css('display', 'block');
                    $(this).closest('.product-detail').find('.form-control').addClass('is-invalid');

                    if (isMobile && productDetailPage) {
                        if (firstErroredOption == null) {
                            firstErroredOption = productOption;
                        }
                    }
                };
            }
        });

        var mobileSearch = $('.search-mobile-row').first();

        if (optionError == true) {
            if (isMobile && productDetailPage) {
                if (firstErroredOption) {
                    if (mobileSearch && mobileSearch.css('display') != 'none') {
                        //Get the parent section of the errored option to scroll to
                        scrollAnimate(firstErroredOption.parent(), mobileSearch.height());
                    }
                }
            }

            return;
        }

        if ($(".variation-attribites option").length > 0) {

            // No dropdown selected
            var $variationAttributes = $(".variation-attribites");

            if ($(".variation-attribites option:selected").index() == 0) {
                $(".attribute").find(".invalid-feedback").css("display", "block");
                $(".attribute").find(".form-control").addClass("is-invalid");

                if (mobileSearch && mobileSearch.css('display') != 'none') {
                    scrollAnimate($variationAttributes, mobileSearch.height());
                }

                return;
            }
        }

        // Variation Tile
        let isProductTile = $(this).closest('.product-detail').hasClass('product-tile');
        let productTileVariation = $(this).closest('.product-detail').find('.sizes-display.variation');

        if (isProductTile && productTileVariation.length > 0) {

            let selectedOption = productTileVariation.find('.size-item.selected');

            if (selectedOption.length === 0) {
                optionError = true;
                $(this).closest('.product-detail').find('.invalid-feedback').css('display','block');
                $(this).closest('.product-detail').find('.form-control').addClass('is-invalid');

                if (isMobile && productDetailPage) {
                    if (firstErroredOption == null) {
                        firstErroredOption = productOption;
                    }
                }

                return;
            };
        }


        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
            if (!$products.length) {
                if ($(this).closest('.quick-view-dialog').length) {
                    $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                } else {
                    $products = $('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                }
            }

            $products.each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: scope.methods.getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = scope.methods.getPidValue($(this));

        quantity = ($(this).hasClass('single-variant-quick-add-to-cart') || $(this).hasClass('quick-product')) ? 1 : scope.methods.getQuantitySelected($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                addToCartUrl = $(this).data('url');
            } else {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: quantity,
            options: scope.methods.getOptions($productContainer)
        };

        if (!$('.bundle-item').length) {
            form.options = scope.methods.getOptions($productContainer);
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    scope.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                    $.spinner().stop();
                    scope.methods.miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
};

//Attributes that display in a select dropdown (default)
integrations.selectAttribute = function () {
    var scope = this;
    $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail').length ? $(this).closest('.product-detail') : $(this).closest('.product-wrapper');
        }

        var selectedValueUrl = e.currentTarget.value;
        if ($productContainer.find('input[name=engravable_message]').val() != null) {
            selectedValueUrl = selectedValueUrl + "&engrave_message=" + encodeURIComponent($productContainer.find('input[name=engravable_message]').val());
        }

        scope.methods.attributeSelect(selectedValueUrl, $productContainer);
    });
};

integrations.methods.bindQuantityStepperButtons = function ($stepper) {
    var methods = this;
    var $select = $stepper.prev('select');
    var min = parseInt($stepper.data('min'));
    var max = parseInt($stepper.data('max'));

    $stepper.find('button').off('click').click(event => {
        event.preventDefault();
        var $button = $(event.target);
        var action = $button.data('action');
        var previousValue = parseInt($stepper.find('input').val());
        var newValue = previousValue;

        if (action === 'increase' && (previousValue + 1 <= max)) {
            newValue++;
        }
        if (action === 'decrease' && (previousValue - 1 >= min)) {
            newValue--;
        }
        if (newValue !== previousValue) {
            $select.find('option[value="' + newValue + '"]').prop('selected', true).change();
            $stepper.find('input').prop('value', newValue).prop('data-qty', newValue);
            methods.updateQuantityStepperDisabledStates($stepper);
            $('body').trigger('quantityStepper:change', $stepper);
        }
    });
}

integrations.updateAttributes = function () {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        response.container.find('#sizeChartModal').attr('data-product', response.data.product.id);

        //Quickview
        if ($('.modal.show .product-quickview>.bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else if ($('.set-items').length) {
            response.container.find('.product-id').text(response.data.product.id);
        } else if ($('.modal.show .product-quickview').length) {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
            $('.modal.show .full-pdp-link').attr('href', response.data.product.selectedProductUrl);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else if ($('.product-quickview-card-bonus').length) {
            var $container = $('.product-quickview-card-bonus');

            $container.data('pid', response.data.product.id);
            $container.find('.product-id').text(response.data.product.id);
            //Main PDP
        } else if ($('.product-detail>.bundle-items').length) {
            response.container.data('pid', response.data.product.id);
            response.container.find('.product-id').text(response.data.product.id);
        } else if ($('.product-set-detail').eq(0)) {
            response.container.data('pid', response.data.product.id);
            response.container.find('.product-id').text(response.data.product.id);
            response.container.find('.add-to-cart').data('pid', response.data.product.id);
        } else {
            $('.product-detail .add-to-cart').data('pid', response.data.product.id);
            $('.product-id').text(response.data.product.id);
            $('.product-detail:not(".bundle-item")').data('pid', response.data.product.id);
        }

        // Product detail attributes
        if ($('.collapsible-asset-content').length && response.data.collapsibleAssetContentHTML) {
            $('.js-product-details-collapsible').remove();
            $('.collapsible-asset-content').prepend(response.data.collapsibleAssetContentHTML);
        }

        //clear errors
        if ($(".attribute").find(".form-control").hasClass("is-invalid")) {
            $(".attribute").find(".invalid-feedback").css("display", "none");
            $(".attribute").find(".form-control").removeClass("is-invalid");
        }

        if (response.data.product.noReturnPolicy) {
            $('.pdp-no-return-message').removeClass('hide-message');
        } else {
            $('.pdp-no-return-message').addClass('hide-message');
        }

    });
};

function updateSizesDisplay(container) {
    const sizesDisplay = container.find('.sizes-display');
    const sizeItems = container.find('.all-sizes .size-item');
    const startIndex = container.data('startIndex') || 0;
    const selectedIndex = container.data('selectedIndex');

    sizesDisplay.empty();

    let numberOfItemsToDisplay = Math.min(sizeItems.length, 4);

    for (let i = 0; i < numberOfItemsToDisplay; i++) {
        let sizeIndex = (startIndex + i) % sizeItems.length;
        let sizeItem = $(sizeItems[sizeIndex]).clone();
        if (sizeIndex === selectedIndex) {
            sizeItem.addClass('selected');
        }
        sizeItem.on('click', function () {
            selectSize(container, sizeIndex);
        });
        sizesDisplay.append(sizeItem);
    }
}

function selectSize(container, index) {
    container.data('selectedIndex', index);
    updateSizesDisplay(container);
    updateAttributeForProductTile(container);
}

function prevClick(container) {
    let startIndex = container.data('startIndex') || 0;
    let sizeItems = container.find('.all-sizes .size-item');
    startIndex = (startIndex - 1 + sizeItems.length) % sizeItems.length;
    container.data('startIndex', startIndex);
    updateSizesDisplay(container);
}

function nextClick(container) {
    let startIndex = container.data('startIndex') || 0;
    let sizeItems = container.find('.all-sizes .size-item');
    startIndex = (startIndex + 1) % sizeItems.length;
    container.data('startIndex', startIndex);
    updateSizesDisplay(container);
}

function initializeSlider(container) {
    container.data('startIndex', 0);
    container.data('selectedIndex', null);

    container.find('.prevBtn').on('click', function () {
        prevClick(container);
    });
    container.find('.nextBtn').on('click', function () {
        nextClick(container);
    });

    updateSizesDisplay(container);
}

window.addEventListener('load', function () {
    $('.js-simple-slider-container').each(function () {
        initializeSlider($(this));
    });

    $('body').trigger('product:qacBorder');
});

$('body').on('search:sort--success search:showMore--success search:filter--success', function () {
    $('.js-simple-slider-container').each(function () {
        initializeSlider($(this));
    });

    $('body').trigger('product:qacBorder');
})

$('body').on('product:qacBorder', function () {
    $('.product-tile').each(function () {
        if ($(this).find('.tile-body-quick-add').length) {
            $(this).addClass('has-quick-add');
        }
    });
})

function updateAttributeForProductTile(container) {
    var $productContainer = container.closest('.product-detail');
    if (!$productContainer.length) {
        $productContainer = $(this).closest('.product-detail').length ? $(this).closest('.product-detail') : $(this).closest('.product-wrapper');
    }
    var selectedSizeItem = $productContainer.find('.size-item.selected').first();

    if (selectedSizeItem.length === 0) {
        return;
    }

    var selectedValueUrl = selectedSizeItem.attr('value');

    //  At this point we don't have engraving, but it will be added soon
    //
    // if ($productContainer.find('input[name=engravable_message]').val() != null) {
    //     selectedValueUrl = selectedValueUrl + "&engrave_message=" +
    //      encodeURIComponent($productContainer.find('input [name=engravable_message]').val());
    // }
    //

    if ($productContainer.find('.sizes-display.variation').length) {
        integrations.methods.attributeSelect(selectedValueUrl, $productContainer);
    }
}

module.exports = integrations;
