'use strict';

function rolexV7Menu() {

    this.constants = {
        speed: 300
    }

    this.init = function () {
        this.initSelectors();
        this.initElements();
        this.initEvents();
    }

    this.initSelectors = function () {
        this.selectors = {
            open: 'open',
            opened: 'opened',
            container: 'div.rolex-v7-menu',
            dropMenu: '[drop-menu]',
            dropMenuHolder: '[drop-menu-holder]',
            button: '[drop-button]'
        }
    }

    this.initElements = function () {
        this.elements = {};
        this.elements.container = $(this.selectors.container);

        this.valid = !!this.elements.container.length;

        this.elements.menu = this.valid ? this.elements.container.find(this.selectors.dropMenu) : null;
        this.elements.button = this.valid ? this.elements.container.find(this.selectors.button) : null;
    };

    this.initEvents = function () {
        const self = this;

        if (!this.valid) {
            return;
        }

        if (!!this.elements.button.length) {
            this.elements.button.on('click', self.handlerToggle.bind(this));
        }
    };

    this.closeMenu = function () {
        if (!this.valid) {
            return;
        }

        if (!!this.elements.button.length) {
            this.elements.container.removeClass(this.selectors.opened);
            this.colapseMenu();
        }
    }

    this.openMenu = function () {
        if (!this.valid) {
            return;
        }

        if (!!this.elements.button.length) {
            this.elements.button.addClass(this.selectors.open);
            this.elements.container.addClass(this.selectors.opened);
            this.expandMenu();
        }
    }

    this.colapseMenu = function () {
        const self = this;
        if (!this.elements.menu.length) {
            return;
        }

        const holder = this.elements.menu.find(this.selectors.dropMenuHolder);
        if (!holder.length) {
            return;
        }

        if (!!this.elements.menu.length) {
            this.elements.menu.animate({ height: 0 }, this.constants.speed, () => {
                self.elements.menu.removeAttr("style");
                self.elements.button.removeClass(self.selectors.open);
                self.elements.container.removeClass(self.selectors.open);
            });
        }
    }

    this.expandMenu = function () {
        const self = this;

        if (!this.elements.menu.length) {
            return;
        }

        const holder = this.elements.menu.find(this.selectors.dropMenuHolder);
        if (!holder.length) {
            return;
        }

        if (!!this.elements.menu.length) {
            this.elements.container.addClass(this.selectors.open);
            this.elements.menu.height(0).animate({ height: holder.outerHeight() }, this.constants.speed, () => {
                self.elements.menu.removeAttr("style");
            });
        }
    }

    this.handlerToggle = function () {
        if (this.elements.container.hasClass(this.selectors.open)) {
            this.closeMenu();
            return;
        }

        this.openMenu();
    }

    this.terminate = function () {
        this.elements.button.off('click', self.handlerToggle);
    };
}

module.exports = function () {
    $(document).ready(() => {
        const rolexMegaMenu = new rolexV7Menu();
        rolexMegaMenu.init();
    })
};
