'use strict';

const _CONSTANTS = {
    markerAttribute: 'google-map-marker',
    locationAttribute: 'google-map-location',
    loadedEvent: 'initGoogleMap'
}

function initMapControls(map, components) {
    if (!map || !components) {
        return;
    }

    const zoomIn = components.controlZoomIn;
    const zoomOut = components.controlZoomOut;

    if (zoomIn) {
        zoomIn.addEventListener('click', () => {
            map.setZoom(map.getZoom() + 1);
        })
    }

    if (zoomOut) {
        zoomOut.addEventListener('click', () => {
            const zoomOutIndex = map.getZoom();
            if (zoomOutIndex - 1 < 5) {
                return;
            }
            map.setZoom(zoomOutIndex - 1);
        })
    }
}

function getDefinedMarkers(components) {
    const { pointerMarker, clusterMarker } = components;

    const markers = {};
    if (pointerMarker) {
        markers.pointer = (uuid) => {
            const marker = document.createElement('div');
            marker.setAttribute(_CONSTANTS.markerAttribute, 'pointer');
            marker.setAttribute(_CONSTANTS.addressMarkerUUID, uuid);
            marker.innerHTML = pointerMarker.innerHTML;
            return marker;
        }
    }

    if (clusterMarker) {
        markers.cluster = (() => {
            const marker = document.createElement('div');
            marker.setAttribute(_CONSTANTS.markerAttribute, 'cluster');
            marker.innerHTML = clusterMarker.innerHTML;
            return marker;
        })
    }

    return markers;
}

function addLocationMarkers(components, positions, map) {
    const markerSet = getDefinedMarkers(components);
    const { AdvancedMarkerElement } = google.maps.marker;
    const markers = positions.map(position => {
        const markerObject = {
            position
        }

        markerObject.content = markerSet.pointer(position.uuid);
        const marker = new AdvancedMarkerElement(markerObject);

        marker.addListener("gmp-click", () => {
            map.panTo(marker.position);
            if (position.uuid) {
                components.container.dispatchEvent(new CustomEvent('select', {
                    detail: {
                        uuid: position.uuid
                    },
                }));
            }
        });

        return marker;
    });

    return markers;
}

function addClusterMarkers(map, markers, components) {
    const GoogleMarkerCluster = require('@googlemaps/markerclusterer');
    const markerSet = getDefinedMarkers(components);
    const { AdvancedMarkerElement } = google.maps.marker;

    const renderer = {
        render: (cluster, clusterStats, map) => {
            const counter = cluster.markers.length;
            const content = markerSet.cluster();

            content.innerHTML = `<span>${counter}</span>`

            const marker = new AdvancedMarkerElement({
                content,
                map,
                position: cluster._position
            });

            return marker;
        }
    }

    const cluster = new GoogleMarkerCluster.MarkerClusterer({ map, markers, renderer })
    return cluster
}

function showMarker(map, options) {
    if (!map || !options) {
        return;
    }

    const { components, positions } = options;
    if (!components || !positions) {
        return;
    }

    const markerObject = {
        position: positions,
        map
    }

    const markerSet = getDefinedMarkers(components);
    markerObject.content = markerSet.pointer('store');

    const { AdvancedMarkerElement } = google.maps.marker;
    new AdvancedMarkerElement(markerObject);
}

function createCustomMarker(map, options) {
    if (!map || !options) {
        return;
    }

    const { components, positions } = options;
    if (!components || !positions || !positions.length || !Array.isArray(positions)) {
        return;
    }

    const { googleMap } = components;

    if (!map || !googleMap) {
        return;
    }

    const markers = addLocationMarkers(components, positions, map);
    addClusterMarkers(map, markers, components);
}

function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}

function initMap(settings) {

    const { container, initialLocation, googleMapID, zoomFactor, options = {} } = settings

    if (!container) {
        return;
    }

    const googleMapOptions = {
        center: initialLocation,
        zoom: zoomFactor,
        mapTypeControl: false,
        disableDefaultUI: true,
        mapId: googleMapID,
        ...options
    }

    const map = new google.maps.Map(container, googleMapOptions);
    return map;
}

function initGoogleMapsApiUrl(googleApiKey, container) {
    if (!googleApiKey || !container) {
        return;
    }

    if (!window.initGoogleMap) {
        window.initGoogleMap = function () {
            document.dispatchEvent(new CustomEvent(_CONSTANTS.loadedEvent));
        }
    }

    const url = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey.value}&libraries=marker&callback=initGoogleMap&v=beta&solution_channel=GMP_CCS_customcontrols_v1`;

    const srcTags = document.head.querySelectorAll('script');
    if (srcTags) {
        let found = false;
        srcTags.forEach(srcTag => {
            found = srcTag.src === url
        });

        if (found) {
            window.initGoogleMap();
            return;
        }
    }

    if (window.google && window.google.maps && window.google.maps.marker) {
        window.initGoogleMap();
        return;
    }

    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.setAttribute('defer', '');
    scriptTag.setAttribute('async', '');
    scriptTag.setAttribute('type', 'text/javascript');

    document.head.append(scriptTag);
}

function getLocations(locations) {
    if (!locations) {
        return;
    }

    const set = [];

    locations.forEach(location => {
        const data = location.attributes[_CONSTANTS.locationAttribute];
        if (data && data.value) {
            try {
                set.push(JSON.parse(data.value));
            } catch (err) {
            }
        }
    })

    return set;
}

module.exports = {
    uuidv4,
    initMap,
    initGoogleMapsApiUrl,
    createCustomMarker,
    initMapControls,
    getLocations,
    showMarker
}