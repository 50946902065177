const main = require('client_core/main');

main.baseFiles.customer = require('./checkout/customer.js');

main.baseFiles.dropdownWidget = require('./components/global/dropdown');
main.baseFiles.rolexV7Menu = require('./components/rolexV7Menu');
main.baseFiles.rolexV7StoreLocatorMap = require('./components/rolexV7StoreLocatorMap').init;
main.baseFiles.rolexStoreLocatorMapCpo = require('./components/rolexStoreLocatorMapCpo').init;
main.baseFiles.rolexV7StoreDetailsMap = require('./components/rolexV7StoreDetailsMap').init;
main.baseFiles.rolexStoreDetailsMapCpo = require('./components/rolexStoreDetailsMapCpo').init;
main.baseFiles.rolexV7ContactUsMessage = require('./components/rolexV7ContactUsMessage');
main.baseFiles.rolexPLPGrid = require('./components/global/plpGrid').init;
main.baseFiles.pdCarousel = require('./components/global/carousel').init;
main.baseFiles.productCarousel = require('./components/global/productCarousel').init;
main.baseFiles.rolexV7FormstackForm = require('./components/rolexV7FormstackForm');

module.exports = main;
