'use strict';

const storeLocator = () => {
    $('body').on('click', '.js-rolex-store-locator-button', function (e) {
        const section = $(this).data('section');
        $('.js-rolex-store-locator-button, .js-rolex-store-locator-section').removeClass('js-active');
        $(this).addClass('js-active');
        $(`.js-rolex-store-locator-section[data-section="${section}"]`).addClass('js-active');
        $('body').trigger('storelocator:fitbounds');
    });

    $('body').on('rolex:mapclicked', function (e, item) {
        const $detailContainer = $('.js-rolex-store-locator-detail');
        $detailContainer.empty().append(item.infoWindowHtml);
        $detailContainer.css('left', '0%');
    });

    $('body').on('click', '.js-rolex-close-store-info', function (e) {
        const $detailContainer = $('.js-rolex-store-locator-detail');
        $detailContainer.css('left', '-100%');
    });
};

module.exports = {
    init: () => {
        storeLocator();
    }
};
