'use strict';

const scrollAnimate = require('base/components/scrollAnimate');

const slideToIndex = index => {
    const $slider = $('.js-rolex-image-slider');

    $slider.attr('data-current-position', index);
    $slider.find('img').addClass('d-none');
    $slider.find(`img.slider-img-${index}`).removeClass('d-none');
    $slider.css('left', `-${(index * 100).toString()}%`);

    $('.js-rolex-image-navigation-bar').removeClass('active');
    $(`.js-rolex-image-navigation-bar.js-navigation-bar-${index}`).addClass('active');
};

const slideImage = count => {
    const $slider = $('.js-rolex-image-slider');
    const sliderImageCount = $slider.children().length;
    let currentPosition = $slider.attr('data-current-position');
    let index = parseInt(currentPosition) + count;
    let maxPosition = parseInt(sliderImageCount) - 1;

    if (index > maxPosition) {
        index = index - (maxPosition + 1);
    } else if (index < 0) {
        index = maxPosition;
    }

    slideToIndex(index);
};

const imageCarousel = () => {
    $('body').on('click', '.js-rolex-image-navigation', function (e) {
        slideImage($(this).hasClass('js-navigate-left') ? -1 : 1);
    });

    $('body').on('click', 'img.js-rolex-image-slider-thumbnail, .js-rolex-image-navigation-bar', function (e) {
        slideToIndex(parseInt($(this).data('image-index')));
    });
};

const contactUs = () => {
    $('body').on('click', '.js-rolex-contact-us-link', function (e) {
        scrollAnimate($('.js-rolex-contact-us'));
    });
};

const modelAvailability = () => {
    $('body').on('click', '.rolex-product-details a.rolex-model-availability-link', function (e) {
        e.preventDefault();
        const $availabilityAsset = $('.rolex-model-availability');
        if ($availabilityAsset.length) {
            scrollAnimate($availabilityAsset);
        }
    });
};

module.exports = {
    init: () => {
        imageCarousel();
        contactUs();
        modelAvailability();
    }
};