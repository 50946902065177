'use strict';

const googleMapUtils = require('./lib/googleMaps/googleMapsUtils');

const _CONSTANTS = {
    selectors: {
        storeLocatorMap: '[store-locator-map="rolex"]',
        storeLocatorViewType: '[store-locator-view-type]',
        storeLocatorViewTypeMap: '[store-locator-view-map]',
        storeLocatorViewTypeList: '[store-locator-view-list]',
        storeLocatorView: '[store-locator-view]',
        storeLocatorAddresses: '[store-locator-addresses]',
        googleMap: '[google-map]',
        googleMapView: '[google-map-view]',
        googleMapAttributes: '[google-map-attributes]',
        pointerMarker: '[google-map-marker="pointer-sample"]',
        clusterMarker: '[google-map-marker="cluster-sample"]',
        controlsContainer: '[google-map-controls]',
        controlZoomIn: '[google-map-zoom-in]',
        controlZoomOut: '[google-map-zoom-out]',
        locations: '[google-map-location]',
        markerSelectorAll: '[google-map-marker="pointer"]',
        attributes: {
            closeCard: 'close-card',
            storeAddressUUID: 'store-address-uuid',
            marker: 'google-map-marker',
            selectedAddress: 'store-locator-view-selected',
            storeLocatorMapID: 'google-map-id',
            googleMap: 'google-map',
            zoomFactor: 'zoom-factor',
            zoomFactorMobile: 'zoom-factor-mobile',
            lat: 'lat',
            lng: 'lng',
            latMobile: 'lat-mobile',
            lngMobile: 'lng-mobile',
        },
    },
    active: 'active',
    events: {
        googleMapInit: 'initGoogleMap',
        select: 'select',
        click: 'click',
        touchstart: 'touchstart'
    }
}

function storeLocatorGoogleMap() {
    this.loaded = false;
    this.map = null
    this.locations = [];

    this.initMap = function () {
        const self = this;
        self.locations = googleMapUtils.getLocations(this.components.locations);
        this.map = googleMapUtils.initMap({
            container: this.components.googleMapView,
            googleMapID: this.googleMapID,
            zoomFactor: this.zoomFactor,
            initialLocation: {
                lat: this.lat,
                lng: this.lng
            },
            options: {}
        });

        googleMapUtils.createCustomMarker(self.map, {
            components: self.components,
            positions: self.locations
        });

        googleMapUtils.initMapControls(self.map, self.components);
    }

    this.init = function (container) {
        const self = this;

        this.components = {
            container: container
        }

        this.initComponents();
        googleMapUtils.initGoogleMapsApiUrl(this.googleApiKey, this.components.container);
        self.initEvents();
    }

    this.initComponents = function () {
        if (!this.components.container) {
            return;
        }

        this.components.storeLocatorViewType = this.components.container.querySelector(_CONSTANTS.selectors.storeLocatorViewType);
        this.components.storeLocatorViewTypeMap = this.components.container.querySelector(_CONSTANTS.selectors.storeLocatorViewTypeMap);
        this.components.storeLocatorViewTypeList = this.components.container.querySelector(_CONSTANTS.selectors.storeLocatorViewTypeList);
        this.components.storeLocatorView = this.components.container.querySelector(_CONSTANTS.selectors.storeLocatorView);
        this.components.storeLocatorAddresses = this.components.container.querySelector(_CONSTANTS.selectors.storeLocatorAddresses);
        this.components.googleMap = this.components.container.querySelector(_CONSTANTS.selectors.googleMap);
        this.components.googleMapView = this.components.container.querySelector(_CONSTANTS.selectors.googleMapView);
        this.components.googleMapAttributes = this.components.container.querySelector(_CONSTANTS.selectors.googleMapAttributes);
        this.components.pointerMarker = this.components.container.querySelector(_CONSTANTS.selectors.pointerMarker);
        this.components.clusterMarker = this.components.container.querySelector(_CONSTANTS.selectors.clusterMarker);
        this.components.controlsContainer = this.components.container.querySelector(_CONSTANTS.selectors.controlsContainer);
        this.components.controlZoomIn = this.components.container.querySelector(_CONSTANTS.selectors.controlZoomIn);
        this.components.controlZoomOut = this.components.container.querySelector(_CONSTANTS.selectors.controlZoomOut);
        this.components.locations = this.components.container.querySelectorAll(_CONSTANTS.selectors.locations);

        if (!this.components.googleMap) {
            return
        }

        const googleApiKey = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.googleMap];
        const googleMapID = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.storeLocatorMapID];
        const zoomFactor = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.zoomFactor];
        const zoomFactorMobile = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.zoomFactorMobile];
        const lat = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.lat];
        const lng = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.lng];
        const latMobile = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.latMobile];
        const lngMobile = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.lngMobile];

        if (!googleApiKey) {
            return
        }

        this.googleApiKey = googleApiKey;
        this.googleMapID = googleMapID.value || googleMapUtils.uuidv4();

        if (window.isMobile()) {
            this.zoomFactor = parseFloat(zoomFactorMobile.value) || 5;
            this.lat = parseFloat(latMobile.value);
            this.lng = parseFloat(lngMobile.value);

            return
        }

        this.zoomFactor = parseFloat(zoomFactor.value) || 5;
        this.lat = parseFloat(lat.value);
        this.lng = parseFloat(lng.value);
    }

    this.initEvents = function () {
        document.addEventListener(_CONSTANTS.events.googleMapInit, this.iniMapHandler.bind(this));
        this.components.container.addEventListener(_CONSTANTS.events.select, this.selectHandler.bind(this));
        this.components.container.addEventListener(_CONSTANTS.events.click, this.clickMapHandler.bind(this));
        this.components.container.addEventListener(_CONSTANTS.events.touchstart, this.clickMapHandler.bind(this));

        if (this.components.storeLocatorViewTypeMap) {
            this.components.storeLocatorViewTypeMap.addEventListener(_CONSTANTS.events.click, this.handlerMapType.bind(this))
        }

        if (this.components.storeLocatorViewTypeList) {
            this.components.storeLocatorViewTypeList.addEventListener(_CONSTANTS.events.click, this.handlerListType.bind(this))
        }
    };

    this.handlerMapType = function () {
        this.components.container.setAttribute('view-state', 'map');
    }

    this.handlerListType = function () {
        this.components.container.setAttribute('view-state', 'list');
    }

    this.iniMapHandler = function () {
        if (this.loaded) {
            return;
        }
        const self = this;
        self.initMap(self.components.container, self.googleMapID);
        this.loaded = true;
    }

    this.selectHandler = function (event) {
        const self = this;
        const uuid = event.detail.uuid;
        if (!uuid) {
            return
        }

        const addresses = self.components.container.querySelectorAll(`[${_CONSTANTS.selectors.attributes.storeAddressUUID}]`);
        addresses.forEach(address => {
            const addressUUID = address.attributes[_CONSTANTS.selectors.attributes.storeAddressUUID]
            if (addressUUID && addressUUID.value) {
                if (addressUUID.value === uuid) {
                    address.classList.add('selected');
                } else {
                    address.classList.remove('selected');
                }
            }
        });
    }

    this.clickMapHandler = function (event) {
        const self = this;
        const markers = self.components.googleMap.querySelectorAll(_CONSTANTS.selectors.markerSelectorAll);
        const { target } = event;
        const { parentElement } = target;

        // Select the marker
        if (parentElement.hasAttribute(_CONSTANTS.selectors.attributes.marker) && parentElement.attributes[_CONSTANTS.selectors.attributes.marker].value === 'pointer') {
            if (self.components.storeLocatorView && !self.components.storeLocatorView.hasAttribute(_CONSTANTS.selectors.attributes.selectedAddress)) {
                self.components.storeLocatorView.setAttribute(_CONSTANTS.selectors.attributes.selectedAddress, "")
            }

            markers.forEach(marker => {
                marker.classList.remove(_CONSTANTS.active);
            });

            parentElement.classList.add(_CONSTANTS.active)
        }

        // Close the card
        if (target.hasAttribute(_CONSTANTS.selectors.attributes.closeCard) || parentElement.hasAttribute(_CONSTANTS.selectors.attributes.closeCard)) {
            self.components.storeLocatorView.removeAttribute(_CONSTANTS.selectors.attributes.selectedAddress);
            markers.forEach(marker => {
                marker.classList.remove(_CONSTANTS.active);
            });
        }
    }

    this.terminate = function () {
        this.components.container.removeEventListener(_CONSTANTS.events.googleMapInit, this.iniMapHandler);
        this.components.container.removeEventListener(_CONSTANTS.events.select, this.selectHandler);
        this.components.container.removeEventListener(_CONSTANTS.events.click, this.clickMapHandler);
        this.components.container.removeEventListener(_CONSTANTS.events.touchstart, this.clickMapHandler);

        if (this.components.storeLocatorViewTypeMap) {
            this.components.storeLocatorViewTypeMap.removeEventListener(_CONSTANTS.events.click, this.handlerMapType)
        }

        if (this.components.storeLocatorViewTypeList) {
            this.components.storeLocatorViewTypeList.removeEventListener(_CONSTANTS.events.click, this.handlerListType)
        }
    };
}

module.exports.storeLocatorGoogleMap = storeLocatorGoogleMap;
module.exports.init = function () {
    $(document).ready(() => {
        const storeLocatorMaps = document.querySelectorAll(_CONSTANTS.selectors.storeLocatorMap);

        if (!storeLocatorMaps) {
            return;
        }

        storeLocatorMaps.forEach(storeLocatorMap => {
            const storeLocator = new storeLocatorGoogleMap();
            storeLocator.init(storeLocatorMap);
        });
    })
};
