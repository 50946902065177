'use strict';
const tinySlider = require('tiny-slider/src/tiny-slider');

const _CONSTANTS = {
    selectors: {
        carousel: '[pd-carousel]'
    }
}

function initTinyCarousel(container) {
    this.widgetID = null;

    this.init = function () {
        this.initComponents();
        this.registrateContainer();
        this.initEvents();
        this.initCarousel();
    }

    this.initComponents = function () {
        this.components = {
            container: container
        }

        this.components.carousel = this.components.container.querySelector('[carousel]');
        this.components.controls = this.components.container.querySelector('[controls]');
        this.components.leftArrow = this.components.container.querySelector('[left-arrow]');
        this.components.rightArrow = this.components.container.querySelector('[right-arrow]');
    }

    this.initCarousel = function () {
        const self = this;

        if (!this.components.carousel) {
            return null;
        }

        this.carousel = tinySlider.tns(
            {
                container: self.components.carousel,
                controlsContainer: self.components.controls || false,
                items: 3,
                slideBy: 1,
                loop: false,
                mouseDrag: true,
                swipeAngle: false,
                speed: 400,
                gutter: 20,
                nav: true,
                navPosition: 'bottom',
                responsive: {
                    1: {
                        items: 2,
                    },
                    768: {
                        items: 3,
                    }
                }
            }
        )

        self.handleArrows(this.carousel.getInfo());
        this.carousel.events.on('transitionEnd', function (o) {
            self.handleArrows(o);
        });
    }

    this.handleArrows = function (o) {

        const startIndex = o.items;
        const currentIndex = o.index + o.items
        const lastIndex = o.slideCount;

        if (currentIndex === startIndex && this.components.leftArrow) {
            this.components.leftArrow.classList.add('hidden');

            if (this.components.rightArrow) {
                this.components.rightArrow.classList.remove('hidden');
            }
        }

        if (currentIndex > startIndex && currentIndex < lastIndex && this.components.leftArrow && this.components.rightArrow) {
            this.components.leftArrow.classList.add('hidden');
            this.components.rightArrow.classList.remove('hidden');
        }

        if (currentIndex === lastIndex && this.components.rightArrow) {
            this.components.rightArrow.classList.add('hidden');

            if (this.components.leftArrow) {
                this.components.leftArrow.classList.remove('hidden');
            }
        }
    }

    this.initEvents = function () {

    }

    this.terminate = function () {

    };

    this.registrateContainer = function () {
        if (!this.components.container) {
            return;
        }
        this.widgetID = this.genID();
        this.components.container.setAttribute('widget-id', this.widgetID)
    }

    this.genID = function () {
        return "10000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }
}

function tinyCarousel() {
    const containers = document.querySelectorAll(_CONSTANTS.selectors.carousel);

    if (containers && containers.length) {
        containers.forEach(container => {
            let widget = new initTinyCarousel(container);
            widget.init();
        });
    }
}

module.exports.init = function () {
    tinyCarousel();
};
