const rolexStoreLocatorMap = require('./rolexV7StoreLocatorMap');

const _CONSTANTS = {
    selectors: {
        storeLocatorMap: '[store-locator-map="rolex-cpo"]',
    }
}

module.exports.init = function () {
    $(document).ready(() => {
        const storeLocatorMaps = document.querySelectorAll(_CONSTANTS.selectors.storeLocatorMap);

        if (!storeLocatorMaps) {
            return;
        }

        storeLocatorMaps.forEach(storeLocatorMap => {
            const storeLocator = new rolexStoreLocatorMap.storeLocatorGoogleMap();
            storeLocator.init(storeLocatorMap);
        });
    })
};
