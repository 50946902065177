'use strict';

const scrollAnimate = require('client_core/components/scrollAnimate')

const _CONSTANTS = {
    selectors: {
        plpGrid: '[widget-plp-grid]',
        filterButton: 'filter-button',
        closeFilterButton: 'close-filter-button',
        ajaxUrl: 'ajax-url'
    }
}

function initPLPGrid(container) {
    this.open = false;
    this.widgetID = null;

    this.init = function () {
        this.initComponents();
        this.registrateContainer();
        this.initEvents();
        this.bodyClass = `cpo-body-no-scroll-${this.widgetID}`;
    }

    this.initComponents = function () {
        this.components = {
            container: container
        }
        this.components.filterButton = container.querySelector(_CONSTANTS.selectors.filterButton);
        this.components.closeFilterButton = container.querySelector(_CONSTANTS.selectors.closeFilterButton);
    }

    this.isAjax = function () {
        return this.components.container.hasAttribute('ajax');
    }

    this.isActive = function () {
        let valueStart = 0;
        let valueStop = Infinity;

        if (this.components.container.hasAttribute('widget-breakpoint-start')) {
            valueStart = parseInt(this.components.container.getAttribute('widget-breakpoint-start'));
        }

        if (this.components.container.hasAttribute('widget-breakpoint-stop')) {
            valueStop = parseInt(this.components.container.getAttribute('widget-breakpoint-stop'));
        }

        const windowWidth = window.innerWidth;

        if (valueStart <= windowWidth && windowWidth <= valueStop) {
            this.closeFilterMenu();
        }
    }

    this.openFilterMenu = function () {
        if (this.components.container.hasAttribute('open')) {
            this.open = false;
            this.components.container.removeAttribute('open');
            document.body.classList.remove(this.bodyClass);
            return;
        }

        this.open = true;
        this.components.container.setAttribute('open', '');
        document.body.classList.add(this.bodyClass);
    }

    this.getTargetElement = function (incEelement, selectorAttribute) {
        let exact = false
        let element = incEelement;

        if (element.hasAttribute(selectorAttribute)) {
            return element;
        }

        if (!exact) {
            while (element.parentElement) {
                element = element.parentElement;
                if (element.hasAttribute(selectorAttribute)) {
                    exact = true;
                    break;
                }
            }
        }

        if (exact) {
            return element
        }

        return null;
    }

    this.closeFilterMenu = function () {
        this.open = false;
        this.components.container.removeAttribute('open');
        document.body.classList.remove(this.bodyClass);
    }

    this.setBarUrl = function (url) {
        const urlPath = url.replace(/^http(s|):\/\//g, '').replace(window.location.host, '');
        window.history.pushState({}, '', urlPath);
    }

    this.processResponse = function (doc) {
        const result = doc.querySelector('#plp-rezult');
        if (!result) {
            return;
        }

        this.components.container.innerHTML = '';
        this.components.container.append(result);
    }

    this.call = function (url, ajaxScrollTo) {
        const self = this;
        this.components.container.classList.add('in-progress');

        const authorization = this.components.container.getAttribute('widget-authorization');
        const headers = new Headers();
        headers.append('Content-Type', 'text/html')

        if (authorization) {
            headers.append('Authorization', authorization);
        }

        fetch(url, {
            method: "GET",
            redirect: "follow",
            mode: 'no-cors',
            headers
        })
            .then(response => {
                //handle response
                return response.text();
            })
            .then(html => {
                let parser = new DOMParser();
                let doc = parser.parseFromString(html, "text/html");

                self.processResponse(doc.body);
                self.components.container.classList.remove('in-progress');

                if (!html) {
                    return;
                }
                self.setBarUrl(url);

                if (ajaxScrollTo) {
                    scrollAnimate($(ajaxScrollTo));
                }
            })
            .catch(error => {
                console.error(error);
                self.components.container.classList.remove('in-progress');
            });
    }

    this.handlerClick = function (event) {
        const ajaxLink = this.getTargetElement(event.target, 'ajax-url');
        const ajaxScrollTo = ajaxLink && ajaxLink.dataset.ajaxScrollTo ? ajaxLink.dataset.ajaxScrollTo : false;
        const filterButtonOpen = this.getTargetElement(event.target, 'filter-button');
        const closeFilterButtonOpen = this.getTargetElement(event.target, 'close-filter-button');

        if (ajaxLink) {
            if (!this.isAjax()) {
                return;
            }
            event.preventDefault();
            let url = ajaxLink.getAttribute('href');
            this.call(url, ajaxScrollTo);
        }

        if (filterButtonOpen) {
            this.openFilterMenu()
        }

        if (closeFilterButtonOpen) {
            this.closeFilterMenu()
        }
    }

    this.initEvents = function () {
        window.addEventListener('resize', this.isActive.bind(this));

        if (this.components.container) {
            this.components.container.addEventListener('click', this.handlerClick.bind(this));
        }
    }

    this.terminate = function () {
        window.removeEventListener('resize', this.isActive);

        if (this.components.container) {
            this.components.container.removeEventListener('click', this.ajaxRequest);
        }
    };

    this.registrateContainer = function () {
        if (!this.components.container) {
            return;
        }
        this.widgetID = this.genID();
        this.components.container.setAttribute('widget-id', this.widgetID)
    }

    this.genID = function () {
        return "10000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }
}

function plpGrid() {
    const containers = document.querySelectorAll(_CONSTANTS.selectors.plpGrid);

    if (containers && containers.length) {
        containers.forEach(container => {
            let widget = new initPLPGrid(container);
            widget.init();
        });
    }
}

module.exports.init = function () {
    $(document).ready(() => {
        plpGrid();
    })
};
