'use strict'

const scrollAnimate = require('client_core/components/scrollAnimate')
const rolexTracking = require('client_core/components/rolexTracking')

function rolexV7FormstackForm() {
    const hideContactsAttribute = 'data-hide-contacts'

    this.init = function () {
        const self = this
        self.initSelectors()
        self.initElements()
        self.initEvents()
    }

    this.initSelectors = function () {
        this.selectors = {
            container: '.js-formstack-rolex-v7',
            nextButton: '.js-next-step',
            backButton: '.js-back-step',
            successMessage: '.js-success'
        }
    }

    this.initElements = function () {
        this.elements = {}
        this.elements.container = $(this.selectors.container)

        this.valid = !!this.elements.container

        this.elements.nextButton = this.valid
            ? this.elements.container.find(this.selectors.nextButton)
            : null
        this.elements.backButton = this.valid
            ? this.elements.container.find(this.selectors.backButton)
            : null
        this.elements.successMessage = this.valid
            ? this.elements.container.find(this.selectors.successMessage)
            : null
    }

    this.initEvents = function () {
        const self = this

        this.elements.backButton

        this.elements.nextButton.on(
            'click',
            self.handlerToggleStepsVisibility.bind(self)
        )

        this.elements.backButton.on(
            'click',
            self.handlerToggleStepsVisibility.bind(self)
        )

        if (this.elements.successMessage.length) {
            scrollAnimate(this.elements.successMessage, 50)
        }

        $('form[id^=fsForm]:visible').find('button[type="submit"]').on('click', function () {
            setTimeout(() => {
                if ($('form[id^=fsForm]:visible').find('div[class^="StyledErrorBannerIcon"]:visible').length === 0) {
                    self.setRolexTracking();
                }
            }, 250);
        });

    }

    this.setRolexTracking = function () {
        const successElement = document.createElement('div')
        successElement.setAttribute("data-rolex-event-id", "contactForm")
        rolexTracking.methods.trackEvent(successElement)
    }

    this.handlerToggleStepsVisibility = function () {
        const container = this.elements.container

        if (container.attr(hideContactsAttribute)) {
            container.removeAttr(hideContactsAttribute)
        } else {
            container.attr(hideContactsAttribute, true)
        }

        this.elements.nextButton.toggle()
        this.elements.backButton.toggle()
    }

    this.terminate = function () {
        const self = this
        this.elements.nextButton.off('click', self.handlerToggleStepsVisibility)
        this.elements.backButton.off('click', self.handlerToggleStepsVisibility)
    }
}

module.exports = function () {
    $(document).ready(() => {
        const formstackForm = new rolexV7FormstackForm()
        formstackForm.init()
    })
}
