var main = require('integrations/main');

main.baseFiles.menu = require('./components/menu');
main.baseFiles.base = require('./product/base');
main.baseFiles.footer = require('./components/footer');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.header = require('./components/header');
main.baseFiles.cart = require('./cart');
main.baseFiles.search = require('./components/search');
main.baseFiles.salesfloor = require('./components/salesfloor');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.wellsFargo = require('wellsFargo/wellsFargo');
main.baseFiles.sidebar = require('./components/sidebar');
main.baseFiles.contactUs = require('./components/contactUs');
main.baseFiles.rolexTracking = require('./components/rolexTracking');
main.baseFiles.grid = require('./components/rolex/grid');
main.baseFiles.detail = require('./components/rolex/detail');
main.baseFiles.storeLocator = require('./components/rolex/storeLocator');
main.baseFiles.clientSideValidation = require('./components/clientSideValidation');
main.baseFiles.formStackMods = require('./formStackMods').init;

/* store locator used to live as its own thing. with our customizations to store locator
  for the main component and rolex's separate file, it's easier to keep it separate but it was added to
  rvw core as a baseFiles include. remove it here and keep the inclusion of storeLocator.js in the templates where it's needed */
delete main.baseFiles.storeLocator;

module.exports = main;
