'use strict';

module.exports = {
    init: () => {
        var isMobile = window.isMobile();

        if (isMobile) {
            var selectedLink = $('.collapsible-sidebar-group').find('.active').attr('data-link');
            if (selectedLink) {
                $('.sidebar-title').text(selectedLink);
            }
        }

        var folderLibrarySidebar = $('.folder-based-navigation');

        if (folderLibrarySidebar) {
            var currentPage = folderLibrarySidebar.find('.active');
            var parentFolder = currentPage.closest('.folder-based-navigation-card');
            var expandFolder = parentFolder.find('.js-folder-based-navigation');

            parentFolder.addClass('active');
            expandFolder.attr('aria-expanded','true');
        }
    }
};