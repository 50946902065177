'use strict';

// <div widget-dropdown widget-boundary>
//     <div>
//         <button widget-trigger>Click</button>
//     </div>
//     <div widget-frame>
//         <div widget-content>
//             ...
//         </div>
//     </div>
// </div>

const _CONSTANTS = {
    selectors: {
        dropdown: '[widget-dropdown]',
        trigger: '[widget-trigger]',
        frame: '[widget-frame]',
        content: '[widget-content]',
    }
}

function initDropdown(container) {
    this.open = false;
    this.widgetID = null;

    this.init = function () {
        this.initComponents();
        this.registrateContainer();
        this.initEvents();
    }

    this.initComponents = function () {
        this.components = {
            container: container
        }
        this.components.dropdown = container.querySelector(_CONSTANTS.selectors.dropdown);
        this.components.trigger = container.querySelector(_CONSTANTS.selectors.trigger);
        this.components.frame = container.querySelector(_CONSTANTS.selectors.frame);
        this.components.content = container.querySelector(_CONSTANTS.selectors.content);
    }

    this.isActive = function () {
        let valueStart = 0;
        let valueStop = Infinity;

        if (this.components.container.hasAttribute('widget-breakpoint-start')) {
            valueStart = parseInt(this.components.container.getAttribute('widget-breakpoint-start'));
        }

        if (this.components.container.hasAttribute('widget-breakpoint-stop')) {
            valueStop = parseInt(this.components.container.getAttribute('widget-breakpoint-stop'));
        }

        const windowWidth = window.innerWidth;

        if (valueStart <= windowWidth && windowWidth <= valueStop) {
            return true
        }

        return false;
    }

    this.initEvents = function () {
        if (this.components.trigger) {
            this.components.trigger.addEventListener('click', this.handlerContent.bind(this));
        }

        if (this.components.frame) {
            this.components.frame.addEventListener("transitionend", this.handlerFrame.bind(this));
        }

        if (this.components.container.hasAttribute('widget-boundary')) {
            document.addEventListener('click', this.isWidget.bind(this));
        }
    }

    this.terminate = function () {
        if (this.components.trigger) {
            this.components.trigger.removeEventListener('click', this.handlerContent);
        }

        if (this.components.frame) {
            this.components.frame.removeEventListener("transitionend", this.handlerFrame);
        }

        if (this.components.container.hasAttribute('widget-boundary')) {
            document.removeEventListener('click', this.isWidget.bind(this))
        }
    };

    this.fetchTarget = function (element) {
        if (element.hasAttribute('widget-dropdown')) {
            return element;
        }

        let currentTarget = element
        do {
            currentTarget = currentTarget.parentElement;
            if (!currentTarget) {
                return null;
            }

            if (currentTarget.hasAttribute('widget-dropdown')) {
                return currentTarget;
            }

        } while (currentTarget.parentElement);

        return null;
    }

    this.isWidget = function (event) {
        if (!this.isActive()) {
            return false;
        }

        const self = this;
        const { target } = event;
        const exactTarget = this.fetchTarget(target);

        if ((!exactTarget || exactTarget.getAttribute('widget-id') !== this.widgetID) && self.open) {
            const innerContentHeight = this.components.content.clientHeight || 0;

            self.open = false;
            this.components.frame.style.height = `${innerContentHeight}px`;

            setTimeout(function () {
                self.components.container.removeAttribute('open');
                self.components.frame.style.height = '0px';
            }, 10);
        }
    }

    this.registrateContainer = function () {
        if (!this.components.container) {
            return;
        }
        this.widgetID = this.genID();
        this.components.container.setAttribute('widget-id', this.widgetID)
    }

    this.genID = function () {
        return "10000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }

    this.handlerContent = function () {
        if (!this.isActive()) {
            return;
        }

        const self = this;
        const innerContentHeight = this.components.content.clientHeight || 0;
        const innerFrameHeight = this.components.frame.clientHeight;

        if (!innerFrameHeight) {
            this.components.frame.style.height = '0px';
            setTimeout(function () {
                self.components.frame.style.height = `${innerContentHeight}px`;
                self.open = true;
            }, 10);
        } else {
            this.components.frame.style.height = `${innerContentHeight}px`;
            setTimeout(function () {
                self.components.container.removeAttribute('open');
                self.components.frame.style.height = '0px';
                self.open = false;
            }, 10);
        }
    }

    this.handlerFrame = function () {
        if (!this.open) {
            this.components.frame.removeAttribute('style');
        } else {
            this.components.container.setAttribute('open', '');
            this.components.frame.removeAttribute('style');
        }

        this.initComponents();
    }
}

function dropdown() {
    const containers = document.querySelectorAll(_CONSTANTS.selectors.dropdown);

    if (containers && containers.length) {
        containers.forEach(container => {
            let widget = new initDropdown(container);
            widget.init();
        });
    }
}

module.exports = function () {
    $(document).ready(() => {
        dropdown();
    })
};
