'use strict';

var base = require('base/checkout/customer');

var GUEST_FORM = '#guest-customer';
var REGISTERED_FORM = '#registered-customer';
var SOCIAL_LOGIN_CARD = '.checkout-oauth-card';
var ERROR_SECTION = '.customer-error';

/**
 *
 * @param {boolean} registered - wether a registered login block will be used
 */
function chooseLoginBlock(registered) {
    $(ERROR_SECTION).find('.alert').remove();
    $('#password').val('');
    if (registered) {
        $(REGISTERED_FORM).removeClass('d-none');
        $(SOCIAL_LOGIN_CARD).addClass('d-none');
        $(GUEST_FORM).addClass('d-none');
        $('#email').val($('#email-guest').val());
    } else {
        $(REGISTERED_FORM).addClass('d-none');
        $(SOCIAL_LOGIN_CARD).removeClass('d-none');
        $(GUEST_FORM).removeClass('d-none');
        $('#email').val('');
    }
}

module.exports = {

    /**
     * Listeners for customer form
     */
    initListeners: function () {
        // 1. password
        var customerLogin = '.js-login-customer';
        var cancelLogin = '.js-cancel-login';
        var registered;
        if (customerLogin.length !== 0) {
            $('body').on('click', customerLogin, function (e) {
                registered = true;
                e.preventDefault();
                chooseLoginBlock(registered);
            });
        }
        if (cancelLogin.length !== 0) {
            $('body').on('click', cancelLogin, function (e) {
                registered = false;
                e.preventDefault();
                chooseLoginBlock(registered);
            });
        }
    },

    methods: {
        clearErrors: base.clearErrors,
        updateCustomerInformation: base.updateCustomerInformation,
        customerFormResponse: base.customerFormResponse,
        isGuestFormActive: base.isGuestFormActive
    },

    vars: {
        GUEST_FORM: GUEST_FORM,
        REGISTERED_FORM: REGISTERED_FORM
    }

};
