'use strict';

/**
 * Performs a check to ensure the grecaptcha library is loaded and executes it.
 * If grecaptcha is not loaded, it retries after a short delay, up to a maximum number of retries.
 *
 * @param {Event} e - The event triggering the check, used to generate a unique action name.
 * @param {Object} next - The settings for the AJAX request to be executed after reCAPTCHA verification.
 * @param {number} [maxRetries=5] - The maximum number of retries to check for grecaptcha availability.
 * @param {number} [retryInterval=100] - The interval (in milliseconds) between retries.
 * @param {number} [currentRetry=0] - Internal parameter to track the current retry count.
 */
function check(e, next, maxRetries = 5, retryInterval = 100, currentRetry = 0) {
    // Check if grecaptcha is defined and not empty
    if (typeof grecaptcha !== 'undefined' && grecaptcha) {
        // Check if grecaptcha is ready
        grecaptcha.ready(function () {
            // Generate a unique action name based on the triggering event
            var actionName = 'submit' + (e && e.target ? (e.target.id + e.target.className).replace(/[^a-zA-Z0-9]+/g, '') : '');

            // Execute reCAPTCHA and get the token
            grecaptcha.execute(window.CachedData.recaptchaSiteKey, { action: actionName }).then(function (token) {
                // Create input elements with the token and action name
                var recaptchaTokenInput = $("<input type='hidden' class='form-control' id='recaptchaToken' name='dwfrm_recaptcha_recaptchaToken' value=" + token + ">");
                var recaptchaActionInput = $("<input type='hidden' class='form-control' id='recaptchaAction' name='dwfrm_recaptcha_recaptchaAction' value=" + actionName + ">");

                // Check the type of next.data and add the token and action to it
                if (typeof next.data == 'string') {
                    next.data += next.data.length === 0 ? '' : '&';
                    next.data += $.param(recaptchaTokenInput) + '&' + $.param(recaptchaActionInput);
                } else {
                    next.data = next.data || {};
                    next.data['dwfrm_recaptcha_recaptchaToken'] = token;
                    next.data['dwfrm_recaptcha_recaptchaAction'] = actionName;
                }

                // Execute the AJAX request with the added reCAPTCHA data. Keep in mind that the function will naturally exit after making the AJAX call, and the control will be returned to the calling context.
                $.ajax(next);
            });
        });
    } else {
        // If grecaptcha is not defined or empty and the maximum number of retries is not reached
        if (currentRetry < maxRetries) {
            // Increase the retry count
            currentRetry++;

            // Retry after the specified interval
            setTimeout(function () {
                // Recursively call check with the same parameters
                check(e, next, maxRetries, retryInterval, currentRetry);
            }, retryInterval);
        } else {
            // Maximum number of retries reached, return empty $.ajax(next);
            var securedRecaptchaHandle = $("<input type='hidden' class='form-control' id='securedRecaptchaHandle' name='dwfrm_recaptcha_securedRecaptchaHandle' value=" + true + ">");

            if (typeof next.data == 'string') {
                next.data += next.data.length === 0 ? '' : '&';
                next.data += $.param(securedRecaptchaHandle);
            } else {
                next.data = next.data || {};
                next.data['dwfrm_recaptcha_securedRecaptchaHandle'] = true;
            }

            $.ajax(next);

        }
    }
}

module.exports = {
    check: check
}
