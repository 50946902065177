'use strict'

const recaptcha = require('core/components/recaptcha')
const rolexTracking = require('client_core/components/rolexTracking')
const scrollAnimate = require('client_core/components/scrollAnimate')

function rolexV7ContactUsMessage() {
    this.init = function () {
        const self = this
        self.initSelectors()
        self.initElements()
        self.initEvents()
    }

    this.initSelectors = function () {
        this.selectors = {
            container: '.js-rolex-v7-contact-us-message',
            form: '.js-form',
            stepMessage: '.js-step-message',
            stepContactInfo: '.js-step-contact-info',
            stepSuccess: '.js-step-success',
            nextButton: '.js-next-step-button',
            backButton: '.js-back-step-button',
        }
    }

    this.initElements = function () {
        this.elements = {}
        this.elements.container = $(this.selectors.container)

        this.valid = !!this.elements.container

        this.elements.form = this.valid ? this.elements.container.find(this.selectors.form) : null
        this.elements.stepMessage = this.valid ? this.elements.container.find(this.selectors.stepMessage) : null
        this.elements.stepContactInfo = this.valid ? this.elements.container.find(this.selectors.stepContactInfo) : null
        this.elements.stepSuccess = this.valid ? this.elements.container.find(this.selectors.stepSuccess) : null
        this.elements.nextButton = this.valid ? this.elements.container.find(this.selectors.nextButton) : null
        this.elements.backButton = this.valid ? this.elements.container.find(this.selectors.backButton) : null
    }

    this.initEvents = function () {
        const self = this

        this.elements.backButton

        this.elements.nextButton.on('click', self.handlerToggleStepsVisibility.bind(self))

        this.elements.backButton.on('click', self.handlerToggleStepsVisibility.bind(self))

        this.elements.form.on('submit', self.handlerFormSubmission.bind(self))
    }

    this.handlerToggleStepsVisibility = function () {
        this.elements.stepMessage.toggle()
        this.elements.stepContactInfo.toggle()
        this.elements.backButton.toggle()
    }

    this.handlerFormSubmission = function (e) {
        e.preventDefault()

        const self = this
        const form = this.elements.form
        const url = form.attr('action')

        $.spinner().start()
        recaptcha.check(e, {
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                if (data.success) {
                    self.displaySuccessMsg()
                    rolexTracking.methods.trackEvent(form)
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl
                }
                console.log(err)
            },
            complete: function () {
                $.spinner().stop()
            },
        })
    }

    this.displaySuccessMsg = function () {
        this.elements.stepContactInfo.hide()
        this.elements.backButton.hide()
        this.elements.stepSuccess.show()

        scrollAnimate(this.elements.stepSuccess, 50)
    }

    this.terminate = function () {
        const self = this
        this.elements.nextButton.off('click', self.handlerToggleStepsVisibility)
        this.elements.backButton.off('click', self.handlerToggleStepsVisibility)
        this.elements.form.off('submit', self.handlerFormSubmission)
    }
}

module.exports = function () {
    $(document).ready(() => {
        const rolexContactUsMessage = new rolexV7ContactUsMessage()
        rolexContactUsMessage.init()
    })
}
