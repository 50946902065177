'use strict';

const salesfloorRefresh = () => {
    const widget = window.sf_widget;
    if (widget && widget.utils) {
        widget.utils.registerContextualService();
    }
};

const addResultsMutationObserver = () => {
    const targetNode = document.getElementById('storeLocatorResults');

    if (targetNode) {
        const config = { attributes: true, childList: true, subtree: true };
        const observer = new MutationObserver(() => {
            $('body').trigger('salesfloor:refresh');
        });

        observer.observe(targetNode, config);
    }
};

module.exports = {
    init: () => {
        salesfloorRefresh();

        $('body').on('salesfloor:refresh', function () {
            salesfloorRefresh();
        });

        $(document).on('DOMNodeInserted', function(e) {
            if(e.target.id=="sf-footer-companion-wrapper"){
                $('body').addClass('salesfloor-banner');
            }
        });

        addResultsMutationObserver();
    }
};
