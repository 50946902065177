const rolexV7StoreDetailsMap = require('./rolexV7StoreDetailsMap');

const _CONSTANTS = {
    selectors: {
        storeDetailsMap: '[store-details-map="rolex-cpo"]'
    }
}

module.exports.init = function () {
    $(document).ready(() => {
        const storeDetailsMaps = document.querySelectorAll(_CONSTANTS.selectors.storeDetailsMap);

        if (!storeDetailsMaps) {
            return;
        }

        storeDetailsMaps.forEach(storeDetailsMap => {
            const storeDetails = new rolexV7StoreDetailsMap.storeDetailsGoogleMap();
            storeDetails.init(storeDetailsMap);
        });
    })
};
