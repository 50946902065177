'use strict';

const failInitialization = () => {
    $('.js-wf-frame-container').addClass('d-none');
    $('.js-wf-failure').removeClass('d-none');
};

// This contains the callback hooks that work with the WF SDK. Refer to the SDK docs to understand the different options for callback data/events
const getCallbacks = () => {
    return {
        general: {
            // general is called as the iframe is initialized. calling handle.checkout here starts the WF process
            ready: function (data) {
                handle.checkout(window.WF_Callback_Configuration);
            },
            error: function (data) {
                failInitialization();
                console.error(data.error_code, ': ', data.error_message);
            },
            secure_configuration_error: function (data) {
                failInitialization();
                console.error(data.error_code, ': ', data.error_message);
            }
        },
        checkout: {
            // TO DO: handle any checkout errors
            error: function(data) {
                failInitialization();
                console.error('checkout error', data);
            },
            recoverable_error: function(data) {
                console.error('checkout recoverable_error', data);
            },
            input_data_error: function(data) {
                console.error('checkout input_data_error', data);
            }
        },
        authorization: {
            // TO DO: this is called when authorization succeeds. we need to take the payload data from WF and save it to our order.
            // The old site copied this data into form fields and posted it during CheckoutServices-SubmitPayment. That pattern could work,
            // or it could use a different strategy to update the order custom data directly
            status: function(data) {
                // this callback triggers after the authorization and includes the applicationId, last4AccountNumber, disclosureBody, and disclosureHeader, timestamp
                // it also includes an encrypted string called wfEncryptedJwe which contains the authorization number
                $('#wfLast4AccountNumber').val(data.last4AccountNumber);
                $('#wfDisclosureHeader').val(data.disclosureHeader);
                $('#wfDisclosureBody').val(data.disclosureBody);
                $('#wfEncryptedJwe').val(data.encryptedJWE);
                $('#wfApplicationId').val(data.applicationId);
                $('#wfPlan').val(data.plan);

                const dateString = data.timestamp;
                const dateObj = new Date(dateString);
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const userLocale = navigator.language || navigator.userLanguage;

                const options = {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  timeZone: userTimeZone
                };

                const formattedDate = dateObj.toLocaleString(userLocale, options);

                $('#wfTimestamp').val(formattedDate);
                $('.submit-payment').trigger('click');
            },
            error: function(data) {
                failInitialization();
                console.error('authorization error', data);
            },
            recoverable_error: function(data) {
                console.error('authorization recoverable_error', data);
            },
        }
    }
};

// The sdk needs a configuration and valid plans to work. The callback config has order data in it so it needs to be
// initialized on page load and whenever the order changes (like a new shipping method is selected)
const initializeCallbacks = () => {
    const $wfWindow = $('.js-wf-frame-container');
    if ($wfWindow.length) {
        try {
            window.WF_Callback_Configuration = $wfWindow.data('wf-configuration');
        } catch (e) {
            window.WF_Callback_Configuration = {};
        }
    }

    $('body').on('checkout:updateCheckoutView', function (e, data) {
        if (data && data.order && data.order.isSignatureCardEnabled) {
            window.WF_Callback_Configuration = data.order.wellsFargo.callbackConfiguration;
        }
    });
};

// goes to our server and retrieves a live WF configuration w/ access token for the client-side SDK
const initializeSDK = () => {
    $.ajax({
        url: window.CachedData.wellsFargo.urls.configuration,
        method: 'GET',
        success: data => {
            if (data) {
                window.WF_AO_SDK_Config = data;
                window.WF_AO_SDK_Config.callbacks = getCallbacks();
                window.handle = WF_SDK_BOOTSTRAP.initialize();
                window.WF_Loaded = true;
            } else {
                window.WF_Loaded = false;
                console.error('WF_AO_SDK_Config not set');
            }
        }
    });
};

const initializeWF = () => {
    $('.signature-credit-card-tab').on('click', function (event) {
        event.preventDefault;
        // the signature card content is not displayed if there are no valid plans
        const isEnabled = $('.js-signature-credit-card-content').length;

        var nameChanged = false;
        var wfConfig = $('.js-wf-frame-container').attr('data-wf-configuration');
        var firstName = $('#billingFirstName').val();
        var lastName = $('#billingLastName').val();
        var grandTotalSum = $('.grand-total .grand-total-sum').attr('data-grand-total-sum');

        if (typeof wfConfig != 'undefined' && wfConfig != null) {
            var parsedWFConfig = JSON.parse(wfConfig);
            if (typeof parsedWFConfig.firstName == 'undefined' || typeof parsedWFConfig.lastName == 'undefined' || parsedWFConfig.firstName != firstName || parsedWFConfig.lastName != lastName) {
                nameChanged = true;
                parsedWFConfig.firstName = firstName;
                parsedWFConfig.lastName = lastName;
                parsedWFConfig.financedAmount = grandTotalSum;

                $('.js-wf-frame-container').attr('data-wf-configuration', JSON.stringify(parsedWFConfig));
                $('.js-wf-frame-container .wells_card_offer').remove();
            }
        }

        if (isEnabled && !window.WF_Loaded || (window.WF_Loaded && nameChanged == true)) {
            initializeCallbacks();
            initializeSDK();
        }
    });
}

module.exports = initializeWF;
