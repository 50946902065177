'use strict';

const googleMapUtils = require('./lib/googleMaps/googleMapsUtils');

const _CONSTANTS = {
    selectors: {
        storeDetailsMap: '[store-details-map="rolex"]',
        storeDetailsView: '[store-details-map-view]',
        storeDetailsAddresses: '[store-locator-addresses]',
        googleMap: '[google-map]',
        googleMapView: '[google-map-view]',
        googleMapAttributes: '[google-map-attributes]',
        markerSelectorAll: '[google-map-marker="pointer"]',
        pointerMarker: '[google-map-marker="pointer-sample"]',
        attributes: {
            marker: 'google-map-marker',
            storeDetailsMapID: 'google-map-id',
            googleMap: 'google-map',
            zoomFactor: 'zoom-factor',
            lat: 'lat',
            lng: 'lng',
        },
    },
    events: {
        googleMapInit: 'initGoogleMap'
    }
}

function storeDetailsGoogleMap() {
    this.loaded = false;
    this.map = null
    this.locations = [];

    this.initMap = function () {
        const self = this;
        self.locations = googleMapUtils.getLocations(this.components.locations);
        this.map = googleMapUtils.initMap({
            container: this.components.googleMapView,
            googleMapID: this.googleMapID,
            zoomFactor: this.zoomFactor,
            initialLocation: {
                lat: this.lat,
                lng: this.lng
            },
            options: {
                disableDoubleClickZoom: true,
                draggable: false,
                scrollwheel: false,
                panControl: false
            }
        });

        googleMapUtils.showMarker(self.map, {
            components: self.components,
            positions: {
                lat: this.lat,
                lng: this.lng
            }
        });
    }

    this.init = function (container) {
        const self = this;

        this.components = {
            container: container
        }

        this.initComponents();
        googleMapUtils.initGoogleMapsApiUrl(this.googleApiKey, this.components.container);
        self.initEvents();
    }

    this.initComponents = function () {
        if (!this.components.container) {
            return;
        }

        this.components.storeDetailsView = this.components.container.querySelector(_CONSTANTS.selectors.storeDetailsView);
        this.components.googleMap = this.components.container.querySelector(_CONSTANTS.selectors.googleMap);
        this.components.googleMapView = this.components.container.querySelector(_CONSTANTS.selectors.googleMapView);
        this.components.googleMapAttributes = this.components.container.querySelector(_CONSTANTS.selectors.googleMapAttributes);
        this.components.pointerMarker = this.components.container.querySelector(_CONSTANTS.selectors.pointerMarker);
        this.components.locations = this.components.container.querySelectorAll(_CONSTANTS.selectors.locations);

        if (!this.components.googleMap) {
            return
        }

        const googleApiKey = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.googleMap];
        const googleMapID = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.storeDetailsMapID];
        const zoomFactor = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.zoomFactor];
        const lat = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.lat];
        const lng = this.components.googleMap.attributes[_CONSTANTS.selectors.attributes.lng];

        if (!googleApiKey) {
            return
        }

        this.googleApiKey = googleApiKey;
        this.googleMapID = googleMapID.value || googleMapUtils.uuidv4();
        this.zoomFactor = parseFloat(zoomFactor.value) || 5;
        this.lat = parseFloat(lat.value);
        this.lng = parseFloat(lng.value);
    }

    this.initEvents = function () {
        document.addEventListener(_CONSTANTS.events.googleMapInit, this.iniMapHandler.bind(this));
    };

    this.iniMapHandler = function () {
        if (this.loaded) {
            return;
        }
        const self = this;
        self.initMap(self.components.container, self.googleMapID);
        this.loaded = true;
    }

    this.terminate = function () { };
}

module.exports.storeDetailsGoogleMap = storeDetailsGoogleMap;
module.exports.init = function () {
    $(document).ready(() => {
        const storeDetailsMaps = document.querySelectorAll(_CONSTANTS.selectors.storeDetailsMap);

        if (!storeDetailsMaps) {
            return;
        }

        storeDetailsMaps.forEach(storeDetailsMap => {
            const storeDetails = new storeDetailsGoogleMap();
            storeDetails.init(storeDetailsMap);
        });
    })
};
